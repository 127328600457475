import * as React from "react"
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

const Header = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const H1 = styled.h1`
  max-width: 90%;
  text-align: center;

  @media (min-width: 768px) {
      max-width: 50%;
  }
`

const WaveEmoji = styled(H1)`
  font-size: 72px;
  margin-bottom: 20px;
`

const H3 = styled.h3`
  max-width: 90%;
  text-align: center;

  @media (min-width: 768px) {
    max-width: 50%;
  }
`

// styles
const pageStyles = {
  color: "#232129",
  padding: 10,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet>
        <title>Full Stack Digital</title>
        <meta name="description" content="A digital agency for all aspects of the development process." />
      </Helmet>
      <Header>
        <WaveEmoji>
          👋
        </WaveEmoji>
        <H1>
          Hi, I'm Eugene, and I do full-stack engineering and product development.
        </H1>
        <H3>
          This site is a work-in-progress. Email me at <a href="mailto:eugene@fullstackdigital.tech" target="_blank" rel="noopener noreferrer">eugene@fullstackdigital.tech</a> to connect.
        </H3>
      </Header>
    </main>
  )
}

export default IndexPage
